import React, { useState, useMemo } from "react";
import {useRouter}  from "next/router";
import { CenteredPopup } from "../../modals/CenteredPopup";
import {UrlButton} from "../../buttons";

const urlMap = {
  "/classes/crafts/other-crafts-classes/bring-your-own-prosecco-and-paint-ceramics-1505591653": "https://classbento.co.uk/pottery-painting-class-london",
  "/classes/crafts/terrarium/build-your-very-own-botanical-boys-eco-system-jar-1510614882": "https://classbento.co.uk/terrarium-masterclass-london",
  "/classes/crafts/jewellery-making/make-a-silver-ring-workshop-with-two-goldsmiths-1549119265": "https://classbento.co.uk/silver-ring-making-workshop-london",
  "/classes/art/pottery-and-mosaics/ceramic-workshop---wembley-alperton-north-london-1550530211": "https://classbento.co.uk/hand-building-and-wheel-throwing-pottery-class-harrow",
  "/classes/art/pottery-and-mosaics/kintsugi-class-for-beginners-1651167172": "https://classbento.co.uk/kintsugi-pottery-class-for-beginners-london",
  "/classes/art/pottery-and-mosaics/wheel-throwing-pottery-class---byob-and-craft-1699221631": "https://classbento.co.uk/wheel-throwing-pottery-class-byob-and-craft-london",
  "/classes/art/printing/woodcut-printing-1512471259": "https://classbento.co.uk/woodcut-printing-workshop-london",
  "/classes/art/graffiti/graffiti-workshop-street-art-tour-east-london": "https://classbento.co.uk/street-art-tour-stencil-making-and-street-art-workshop-london",
  "/classes/art/other-art-classes/mini-rug-tufting-workshop-1668611883": "https://classbento.co.uk/mini-rug-tufting-workshop-london",
  "/classes/crafts/jewellery-making/wax-carve-a-silver-ring-with-two-goldsmiths-in-london-bridge-1566140063": "https://classbento.co.uk/silver-ring-design-workshop-london",
  "/classes/art/painting/kids-and-adult-art-diy---acrylic-fluid-bear-1673299505": "https://classbento.co.uk/fluid-bear-acrylic-pouring-class-london",
  "/classes/crafts/paper/christmas-card-and-wrapping-paper-lino-printing-1632302740": "https://classbento.co.uk/christmas-card-making-class-with-lino-printing-london",
  "/classes/crafts/floristry/christmas-wreath-making-workshop-1573127582": "https://classbento.co.uk/christmas-wreath-making-workshop-london-1",
  "/classes/art/pottery-and-mosaics/all-levels-mosaic-and-tile-making-classes-in-stockwell-1508932803": "https://classbento.co.uk/mosaic-making-class-mosaic-tiles-london-0",
  "/classes/art/pottery-and-mosaics/sunday-wheel-throwing-workshop-1597428634": "https://classbento.co.uk/ceramics-class-taster-london",
  "/classes/crafts/jewellery-making/streetwear-jewellery-making-workshop-1559208380": "https://classbento.co.uk/jewellery-making-workshop",
  "/classes/art/painting/introduction-to-oil-painting-1519682408": "https://classbento.co.uk/oil-painting-class-kingston",
  "/classes/art/printing/christmas-bauble-marbling-workshop-1632868192": "https://classbento.co.uk/christmas-bauble-marbling-workshop",
  "/classes/crafts/floristry/christmas-wreath-making-workshop-1605304930": "https://classbento.co.uk/christmas-wreath-making-workshop-2",
  "/classes/art/welding/introductory-welding-for-artists-woolwich-south-east-london": "https://classbento.co.uk/introduction-to-welding-for-artists-class-london",
  "/classes/art/pottery-and-mosaics/pottery-taster-session-1579348265": "https://classbento.co.uk/ceramics-class-taster-london",
  "/classes/art/drawing/neon-naked-life-drawing--west-london-1526039497": "https://classbento.co.uk/neon-life-drawing-class",
  "/classes/food/cooking/dim-sum-cookery-class-1542105601": "https://classbento.co.uk/dim-sum-cooking-class-london-0",
  "/classes/crafts/other-crafts-classes/epoxy-resin-workshop-1547744504": "https://classbento.co.uk/beginners-epoxy-resin-workshop-london-0",
  "/classes/crafts/other-crafts-classes/jesmonite-casting-workshop---make-your-own-terrazzo-plant-pot-1581854720": "https://classbento.co.uk/jesmonite-casting-workshop-terrazzo-plant-pot-london",
  "/classes/crafts/candle-making/vegan-candle-making-1572541085": "https://classbento.co.uk/vegan-friendly-candle-making-class",
  "/classes/art/pottery-and-mosaics/pottery-taster-workshop-1502395930": "https://classbento.co.uk/pottery-making-taster-workshop-london"
};

export function DisabledBookingPopup({ onClose, popupData }: Props) {

  const router = useRouter();

  const currentPath = router.asPath.split("#")[0]; // Remove fragment identifier

  // Use popupData if needed
  const isGiftButton = popupData?.gift_button === 1;
  // Get the related ClassBento URL
  // Step 1: Create a type for the keys of urlMap
  type UrlMapKeys = keyof typeof urlMap;

// Step 2: Create a type guard function
  function isUrlMapKey(key: string): key is UrlMapKeys {
    return key in urlMap;
  }

  //console.log("Is Gift Button",isGiftButton);

  // Determine the related URL
  const relatedUrl = useMemo(() => {
    if (isGiftButton) {
      if (isUrlMapKey(currentPath)) {
        return `${urlMap[currentPath]}?gift_first=1`;
      }
      return "https://classbento.co.uk/workshop-experience-gift-cards";
    }
    return isUrlMapKey(currentPath) ? urlMap[currentPath] : "https://classbento.co.uk";
  }, [currentPath, isGiftButton]);

  // Determine button text
  const buttonText = isGiftButton ? "Gift on ClassBento" : "Book on ClassBento";
  const titleText = isGiftButton ? "Please gift on ClassBento" : "Please book on ClassBento";
  const acquiredText = isGiftButton ? "Obby has been acquired by ClassBento. Click to gift now!" : "Obby has been acquired by ClassBento. Click to book now!";

// Step 3: Use the type guard function
  //const relatedUrl = isUrlMapKey(currentPath) ? urlMap[currentPath] : "https://classbento.co.uk"; // Default if not found in the map
  return (
    <CenteredPopup
      title={titleText}
      closeButtonPosition="cross"
      onClose={onClose}
      acceptButtonText="Send Request"
      size="medium"
    >
      <div>
        <p>{acquiredText}</p>
        <UrlButton
          href={relatedUrl}
          text={buttonText}
          className="mt-2" />

      </div>
    </CenteredPopup>
  );
}

interface Props {
  onClose: () => void;
  popupData?: {
    gift_button?: number;
  }
}

DisabledBookingPopup.defaultProps = {
  popupData:{ gift_button : 0 },
}
